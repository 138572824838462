
import { Outlet } from 'react-router-dom'
// import { Layout, Modal } from 'antd'
import { Layout } from 'antd'
import AuthAction from './AuthAction'
// import { useDapp } from '../services/dapp'

// import { MessageOutlined } from '@ant-design/icons'
// import WalletNetwork from './WalletNetwork'
const Root = () => {
  // const { wallet } = useDapp()
  // console.log(wallet, wallet.account)
  return <Layout>
    <Layout.Header style={{ textAlign: 'right' }}>
      {/* <WalletNetwork/>  */}
      <AuthAction />
    </Layout.Header>
    {/* {`${wallet?.account}`.toLowerCase()  !== '0xC9C0Ce4C1BF1cdFaDe074B38EdA41c26174eC621'.toLowerCase() 
      && <Modal visible closable={false} footer={null} >
      <div style={{ padding: '2em' }}>
        เปิดให้รับรางวัล BS42 King Kong NFT <br />
        ได้ตั้งแต่วันที่ 21 มี.ค. 2565 เวลา 12:00
      </div>
    </Modal>} */}
    <Layout.Content style={{ padding: '1em', minHeight: '100vh' }}><Outlet /></Layout.Content>
    {/* <Button type="primary" shape="round" icon={<MessageOutlined />} /> */}
    {/* <Layout.Footer>Footer</Layout.Footer> */}
  </Layout>
}

export default Root