export const networkName = process.env.REACT_APP_NETWORK_NAME

const networkConfig = {
  // bitkub: {
  //   chainId: 96,
  //   metamaskConfig: {
  //     chainId: `0x${(96).toString(16)}`, // A 0x-prefixed hexadecimal string
  //     chainName: 'Polygon Testnet Mumbai',
  //     nativeCurrency: {
  //       name: 'KUB',
  //       symbol: 'KUB',
  //       decimals: 18
  //     },
  //     rpcUrls: [
  //       'http://rpc.bitkubchain.io',
  //     ],
  //     blockExplorerUrls: [
  //       'https://bkcscan.com/'
  //     ]
  //   }
  // },
  mumbai: {
    chainId: 80001,
    openseaBaseUri: 'https://testnets.opensea.io',
    metamaskConfig: {
      chainId: `0x${(80001).toString(16)}`, // A 0x-prefixed hexadecimal string
      chainName: 'Polygon Testnet Mumbai',
      nativeCurrency: {
        name: 'MATIC',
        symbol: 'MATIC',
        decimals: 18
      },
      rpcUrls: [
        'https://matic-mumbai.chainstacklabs.com',
        'https://rpc-mumbai.maticvigil.com',
        'https://matic-testnet-archive-rpc.bwarelabs.com'
      ],
      blockExplorerUrls: [
        'https://mumbai.polygonscan.com/'
      ]
      // iconUrls?: string[]; // Currently ignored.
      // name: 'Polygon Testnet Mumbai',
      // chain: 'Polygon',
      // rpc: [
      //   'https://matic-mumbai.chainstacklabs.com',
      //   'https://rpc-mumbai.maticvigil.com',
      //   'https://matic-testnet-archive-rpc.bwarelabs.com'
      // ],
      // faucets: [
      //   'https://faucet.polygon.technology/'
      // ],
      // nativeCurrency: {
      //   name: 'MATIC',
      //   symbol: 'MATIC',
      //   decimals: 18
      // },
      // infoURL: 'https://polygon.technology/',
      // shortName: 'maticmum',
      // chainId: 80001,
      // networkId: 80001,
      // explorers: [
      //   {
      //     name: 'polygonscan',
      //     url: 'https://mumbai.polygonscan.com/',
      //     standard: 'EIP3091'
      //   }
      // ]
    }
  },
  matic: {
    chainId: 137,
    openseaBaseUri: 'https://opensea.io',
    metamaskConfig: {
      chainId: `0x${(137).toString(16)}`, // A 0x-prefixed hexadecimal string
      chainName: 'Polygon Mainnet',
      nativeCurrency: {
        name: 'MATIC',
        symbol: 'MATIC',
        decimals: 18
      },
      rpcUrls: [
        'https://polygon-rpc.com/',
        'https://rpc-mainnet.matic.network',
        'https://matic-mainnet.chainstacklabs.com',
        'https://rpc-mainnet.maticvigil.com',
        'https://rpc-mainnet.matic.quiknode.pro',
        'https://matic-mainnet-full-rpc.bwarelabs.com'
      ],
      blockExplorerUrls: [
        'https://polygonscan.com/'
      ]
      // name: 'Polygon Mainnet',
      // chain: 'Polygon',
      // rpc: [
      //   'https://polygon-rpc.com/',
      //   'https://rpc-mainnet.matic.network',
      //   'https://matic-mainnet.chainstacklabs.com',
      //   'https://rpc-mainnet.maticvigil.com',
      //   'https://rpc-mainnet.matic.quiknode.pro',
      //   'https://matic-mainnet-full-rpc.bwarelabs.com'
      // ],
      // faucets: [],
      // nativeCurrency: {
      //   name: 'MATIC',
      //   symbol: 'MATIC',
      //   decimals: 18
      // },
      // infoURL: 'https://polygon.technology/',
      // shortName: 'MATIC',
      // chainId: 137,
      // networkId: 137,
      // slip44: 966,
      // explorers: [
      //   {
      //     name: 'polygonscan',
      //     url: 'https://polygonscan.com/',
      //     standard: 'EIP3091'
      //   }
      // ]
    }
  }
}

export const network = networkConfig[networkName]


// chainId: string; // A 0x-prefixed hexadecimal string
// chainName: string;
// nativeCurrency: {
//   name: string;
//   symbol: string; // 2-6 characters long
//   decimals: 18;
// };
// rpcUrls: string[];
// blockExplorerUrls ?: string[];
// iconUrls ?: string[]; // Currently ignore