import { createContext, useContext, useEffect, useState } from "react"
import { ethers } from 'ethers'
import { useWallet } from 'use-wallet'
import { network, networkName } from '../config'
import { UseWalletProvider } from 'use-wallet'

// const contract = new ethers.Contract(process.env.REACT_APP_CONTRACT_ADDRESS, BSNFT.abi)

const { rpcUrls: [rpcUrl] } = network?.metamaskConfig
const walletconnect = {
  rpc: {
    [network?.chainId]: rpcUrl
  }
}

const DappContext = createContext({})
const useDappProvider = ({ contractAddress, contractAbi }) => {
  const wallet = useWallet()
  const [contract, setContract] = useState()
  const [signer, setSigner] = useState()
  const [provider, setProvider] = useState()
  const setNetwork = async (config) => {
    try {
      await wallet.ethereum?.request({
        method: 'wallet_switchEthereumChain',
        // params: [{ chainId: BigNumber.from(137).toHexString() }],
        // params: [{ chainId: BigNumber.from(80001).toHexString() }],
        params: [{ chainId: config.chainId }],
      })
    } catch (error) {
      // alert(JSON.stringify(error))
      if ([error.code, error.data?.originalError.code].includes(4902) || error.code === -32603) {
        await wallet.ethereum?.request({
          method: 'wallet_addEthereumChain',
          params: [config],
        })
      }
    }
  }
  const switchNetwork = () => {
    setNetwork(network.metamaskConfig)
  }
  // console.log({ wallet })
  useEffect(() => {
    // console.log('dapp useEffect', wallet.ethereum)
    if (wallet.ethereum) {
      const _provider = new ethers.providers.Web3Provider(wallet.ethereum)
      const _signer = _provider.getSigner()
      // console.log({ wallet })
      const _contract = new ethers.Contract(contractAddress, contractAbi, _signer)
      setProvider(_provider)
      setSigner(_signer)
      setContract(_contract)
      
      if (wallet.chainId !== network.chainId) {
        setNetwork(network.metamaskConfig)
        // console.log({ response })
      }

      // const provider = new ethers.providers.Web3Provider(wallet.ethereum)
      // const _signer = provider.getSigner()
      // setSigner(_signer)
      // console.log({ wallet })
    }
  }, [wallet.ethereum]) //eslint-disable-line react-hooks/exhaustive-deps
  return {
    wallet,
    provider,
    signer,
    contract,
    network,
    networkName,
    switchNetwork
  }
}

export const useDapp = () => useContext(DappContext)

const DappContextProvider = ({ contractAddress, contractAbi, children }) => <DappContext.Provider value={useDappProvider({ contractAddress, contractAbi })}>
  {children}
</DappContext.Provider>

export const DappProvider = ({ contractAddress, contractAbi, children }) => <UseWalletProvider autoConnect chainId={network?.chainId} connectors={{ walletconnect }}>
  <DappContextProvider
    contractAddress={contractAddress}
    contractAbi={contractAbi}
  >
    {children}
  </DappContextProvider>
</UseWalletProvider>

