import { useState } from 'react'
// import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

const ImageLoader = ({ alt, ...props }) => {
  const [loaded, setLoaded] = useState(false)
  // console.log(props.src)
  return <>
    {!loaded && <div className="square">
      {/* <Spin /> */}
      <div className="square-content">
        <LoadingOutlined />
      </div>
    </div>}
    <img alt={alt} {...props} onLoad={() => setLoaded(true)} />
  </>
}

// TODO: handle image load error

export default ImageLoader