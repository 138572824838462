import MetaMaskOnboarding from '@metamask/onboarding'
import { useEffect, useRef, useState } from 'react';
// import { ethers, BigNumber, Signer } from 'ethers'
import { Button } from 'antd'
// import BSNFT from '@contracts/BSNFT.sol/BangsaenNFT.json'
// import { useWallet } from 'use-wallet'
import { LinkOutlined, DisconnectOutlined, DownloadOutlined } from '@ant-design/icons'
import { useDapp } from '../services/dapp'
import { isBrowser, isMobile } from 'react-device-detect';
const AuthAction = () => {
  const { wallet } = useDapp()
  const [mmInstalled, setMmInstalled] = useState(false) 
  // console.log({ wallet, contract })
  // console.log(wallet?.connector)
  const onboarding = useRef();
  const $mmIcon = <img alt="metamask" src='/metamask.svg' style={{ height: '2em', margin: '-1em 0' }} />

  useEffect(() => {
    if (!onboarding.current) {
      onboarding.current = new MetaMaskOnboarding();
    }
    setMmInstalled(MetaMaskOnboarding.isMetaMaskInstalled())
  }, []);
  const handleInstallMetaMask = () => {
    onboarding.current.startOnboarding();
  }
  return <>
    {mmInstalled ? <>
      {wallet?.status === 'disconnected' && <Button shape="round" icon={<LinkOutlined />} onClick={() => wallet?.connect()}>เชื่อมต่อด้วย MetaMask {$mmIcon}</Button>}
      {wallet?.status === 'connecting' && <Button shape="round" icon={<LinkOutlined />} loading>กำลังเชื่อมต่อ...</Button>}
      {wallet?.status === 'connected' && <Button shape="round" icon={<DisconnectOutlined />} onClick={() => wallet?.reset()}>ยกเลิกการเชื่อมต่อ {`${wallet.account.slice(0, 4)}...${wallet.account.slice(-4)}`}</Button>}
    </>: <>
      {isBrowser && <Button shape="round" icon={<DownloadOutlined />} onClick={handleInstallMetaMask}>ติดตั้ง MetaMask Extension {$mmIcon}</Button>}
      {isMobile && <Button shape="round" icon={<DownloadOutlined />}><a href={`https://metamask.app.link/dapp/${window.location.host}${window.location.pathname}`}>ติดตั้งแอปพลิเคชัน MetaMask {$mmIcon}</a></Button>}
      {/* <Button shape="round" onClick={handleInstallMetaMask}>ติดตั้ง MetaMask Extension<img alt="metamask" src='/metamask.svg' style={{ height: '2em', margin: '-1em 0' }} /></Button> */}
    </>}
    {/* {!mmInstalled && <div>กรุณาติดตั้ง MetaMask</div>} */}
    
  </>
}

export default AuthAction