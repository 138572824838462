
import { Card, Button, Modal, Steps, Descriptions, Tag } from 'antd'
import { useDapp } from '../services/dapp'
import AuthAction from './AuthAction'
import { useParams } from 'react-router-dom'
import { getIpfsUrl } from '../services/ipfs'
import { LoadingOutlined, ArrowUpOutlined } from '@ant-design/icons'
import { useState } from 'react'
import { useClaimCode, useData } from '../services/trnft'
import ImageLoader from './ImageLoader'

const txSteps = [{
  key: 'claiming',
  title: `ตรวจสอบรหัส`
}, {
  key: 'confirming',
  title: `ตรวจสอบธุรกรรม`
}, {
  key: 'claimed',
  title: `สำเร็จ`
}]

const TxSteps = ({ txStatus }) => {
  const current = txSteps.findIndex(s => s.key === txStatus)
  return <Steps size="small" current={current}>
    {txSteps.map((s, i) =>
      <Steps.Step
        key={s.key}
        title={s.title}
        icon={current === i && (s.key === 'claimed' ? <img alt="successful" src='/check-circle.gif' width={28} /> : <LoadingOutlined />)}
      />
    )}
  </Steps>
}

const ClaimCode = () => {
  const { event, code } = useParams()
  const { wallet, contract, network, networkName, switchNetwork } = useDapp()
  const { claim, txStatus, txHash } = useClaimCode({ event, code })
  const { loading, tokenId, tokenMetadata, tx, owner, error } = useData({ event, code })
  const [txModalOpen, setTxModalOpen] = useState(true)
  if (error) {
    return `${JSON.stringify(error)}`
  }

  if (wallet?.status && !['connected', 'connecting'].includes(wallet.status)) {
    return <Modal
      title="กรุณาเชื่อมต่อวอลเล็ทของท่าน"
      style={{ textAlign: 'center' }}
      visible={true}
      footer={null}
      closable={false}
    >
      <AuthAction />
      <p style={{ margin: '2em' }}>
        <a href="/how" target="_blank" rel="noreferrer">ดูขั้นตอนการรับรางวัล NFT <ArrowUpOutlined style={{ transform: 'rotate(45deg)' }} /></a>
      </p>
    </Modal>
  }
  if (wallet.status === 'connected' &&  wallet.chainId !== network.chainId) {
    return <Modal
      style={{ textAlign: 'center' }}
      visible={true}
      footer={null}
      closable={false}
    >
      <p>กรุณาเปลี่ยนเครือข่ายเป็น <Tag>{network.metamaskConfig.chainName}</Tag></p>
      <Button shape="round" type="primary" onClick={switchNetwork}>เปลี่ยนเครือข่าย</Button>
      <p style={{ margin: '2em' }}>
        <a href="/how" target="_blank" rel="noreferrer">ดูขั้นตอนการรับรางวัล NFT <ArrowUpOutlined style={{ transform: 'rotate(45deg)' }} /></a>
      </p>
    </Modal>
  }
  if (loading) {
    return <div style={{ width: '100%', textAlign: 'center', margin: '2em 0' }}>
      <LoadingOutlined style={{ fontSize: 64 }} />
      <div style={{ margin: '2em' }}>กำลังดาวน์โหลดข้อมูล...</div>
    </div>
  }
  const handleClaim = () => {
    claim()
    setTxModalOpen(true)
  }
  const tokenImage = tokenMetadata?.image && (
    tokenMetadata.image.startsWith('ipfs') 
    ? getIpfsUrl(tokenMetadata.image) 
    : tokenMetadata.image
  )
  const { blockExplorerUrls: [blockExplorerUrl] } = network.metamaskConfig
  return <>
    {/* <Modal
      style={{ textAlign: 'center' }}
      visible={wallet?.status && !['connected', 'connecting'].includes(wallet.status)}
      footer={null}
      closable={false}
    >
      <p>Please connect your wallet</p>
      <AuthAction />
    </Modal> */}
    {['claiming', 'confirming', 'claimed'].includes(txStatus) && <Modal
      visible={txModalOpen}
      title="สถานะการรับรางวัล"
      onCancel={() => setTxModalOpen(false)}
      closable={['claimed'].includes(txStatus)}
      maskClosable={false}
      footer={['claimed'].includes(txStatus) && <Button onClick={() => setTxModalOpen(false)}>ปิด</Button>}
    >
      <TxSteps txStatus={txStatus} />
      <div style={{ textAlign: 'center', padding: '2em' }}>
        {['claiming', 'confirming'].includes(txStatus) && `การรับรางวัล อาจใช้เวลา 1 - 10 นาที กรุณารอสักครู่`}
        {txHash && <p><a href={`${blockExplorerUrl}tx/${txHash ?? tx}`} target="_blank" rel='noreferrer'>
          ดูรายละเอียดธุรกรรมที่ PolygonScan <ArrowUpOutlined style={{ transform: 'rotate(45deg)' }} />
        </a></p>}
      </div>
    </Modal>}
    {!!owner || txStatus === 'claimed' ? <Card style={{ maxWidth: 512, margin: 'auto' }} cover={<ImageLoader alt="Bangsaen Series NFT" src={tokenImage} />}>
      {/* claimed */}
      <Button block style={{ marginBottom: '1em' }}>
        <a href={`${network.openseaBaseUri}/assets/${networkName}/${contract.address}/${tokenId}`} rel="noreferrer" target="_blank">
          ดู NFT ที่ OpenSea <ArrowUpOutlined style={{ transform: 'rotate(45deg)' }} />
        </a>
      </Button>
      <Button block style={{ marginBottom: '1em' }}>
        <a href={`${blockExplorerUrl}tx/${txHash ?? tx}`} rel="noreferrer" target="_blank">
          ตรวจสอบธุรกรรมที่ PolygonScan <ArrowUpOutlined style={{ transform: 'rotate(45deg)' }} />
        </a>
      </Button>
      <Descriptions title={tokenMetadata?.name} size="small" column={{ xs: 1, sm: 2 }}>
        {owner && <Descriptions.Item key="owner" label="Owner">
          <a href={`${network.openseaBaseUri}/${owner}`} rel="noreferrer" target="_blank">
            {`${owner?.slice(0, 4)}...${owner?.slice(-4)}`}
          </a>
        </Descriptions.Item>} 
        {tokenMetadata?.attributes?.map(a =>
          <Descriptions.Item key={a.trait_type} label={a.trait_type}>
            {a.value}
          </Descriptions.Item>  
        )}
      </Descriptions>
      {/* TODO: show placeholeder image */}
    </Card> : <Card style={{ maxWidth: 512, margin: 'auto' }} cover={<ImageLoader alt="Bangsaen Series NFT" src="/mystery.jpeg" />} >
      {/* unclaimed */}
      <p style={{ margin: '2em' }}>
        <a href="/how" target="_blank" rel="noreferrer">ดูขั้นตอนการรับรางวัล NFT <ArrowUpOutlined style={{ transform: 'rotate(45deg)' }} /></a>
      </p>
      <Button
        block
        type='primary'
        onClick={handleClaim}
        loading={['claiming', 'confirming'].includes(txStatus)}
      >
        รับรางวัล
      </Button>

    </Card>}
  </>
}

export default ClaimCode