import { Typography, Collapse } from "antd"

const How = () => {
  return <div className="howto-content">
    <Typography.Title level={2}>ขั้นตอนการรับ BS42 NFT</Typography.Title>
    <Collapse ghost>
      <Collapse.Panel header="การรับรางวัลโดยใช้สมาร์ทโฟน" key="mobile">
        <ol>
          <li>
            เข้าเว็บไซต์การรับรางวัล โดยการสแกน QR Code หากท่านยังไม่ได้ติดตั้ง MetaMask ให้สแกน QR Code ผ่านแอปพลิเคชันกล้องถ่ายรูป หรือ LINE <br />
            ท่านสามารถกดที่ปุ่ม "ติดตั้งแอปพลิเคชัน MetaMask" เพื่อดำเนินการต่อไป <br />
            <Collapse ghost>
              <Collapse.Panel header="การติดตั้งแอปพลิเคชัน MetaMask" key="application">
                <a href="https://www.youtube.com/watch?v=M0cyRr0yV7Q" target="_blank" rel="noreferrer">ดูคลิปอธิบายขั้นตอนการติดตั้งแอปพลิเคชัน MetaMask</a>
              </Collapse.Panel>
            </Collapse>
            <img alt="initial step" src="/mobile-0.jpg" /><br />
            {/* เมื่อติดตั้งสำเร็จแล้ว ให้ท่านสแกน QR Code อีกครั้งผ่านแอปพลิเคชัน MetaMask */}
            เมื่อติดตั้งแอปพลิเคชันเรียบร้อยแล้ว ให้ท่านเข้าเว็บไซต์การรับรางวัล โดยการสแกน QR Code ผ่านแอปพลิเคชัน MetaMask <br />
            <img alt="first step" src="/mobile-1.jpg" />
          </li>
          <li>
            เชื่อมต่อกับดิจิทัลวอลเล็ท โดยการกดปุ่ม "เชื่อมต่อด้วย MetaMask" <br />
            <img alt="second step" src="/mobile-2.jpg" />
          </li>
          <li>
            ยืนยันการเชื่อมต่อ โดยการกดปุ่ม "Connect" <br />
            <img alt="third step" src="/mobile-3.jpg" />
          </li>
          <li>
            เนื่องจากรางวัล ถูกบันทึกอยู่บนเครือข่าย Polygon Mainnet หากท่านยังไม่เคยเชื่อมต่อกับเครือข่ายนี้ <br/>
            จะต้องอนุญาตให้ดิจิทัลวอลเล็ทของท่านทำการเชื่อมต่อ โดยการกด "Approve" <br />
            <img alt="fourth step" src="/mobile-4.jpg" />
          </li>
          <li>
            ทำการเชื่อมต่อกับเครือขาย Polygon Mainnet โดยการกด "Switch Network" <br />
            <img alt="fifth step" src="/mobile-5.jpg" />
          </li>
          <li>
            ระบบจะทำการดาวน์โหลดข้อมูล NFT อาจใช้เวลาสักครู่ <br />
            ท่านสามารถรับ NFT โดยการกด "รับรางวัล"<br />
            <img alt="sixth step" src="/mobile-6.jpg" />
          </li>
          <li>
            กด "Sign" เพื่อยืนยันตัวตนเพื่อทำธุรกรรมบนบล็อกเชน <br />
            <img alt="seventh step" src="/mobile-7.jpg" />
          </li>
          <li>
            รอการตรวจสอบรหัสขั้นตอนนี้อาจใช้เวลาถึง 1 นาที <br />
            <img alt="eighth step" src="/mobile-8.jpg" />
          </li>
          <li>
            เครือข่ายบล็อกเชนได้รับข้อมูลธุรกรรม การโอน NFT แล้ว และกำลังตรวจสอบความถูกต้อง<br />
            ขั้นตอนนี้อาจใช้เวลาถึง 10 นาที ท่านสามารถดูรายละเอียดธุรกรรมได้ โดยกดลิงก์ PolygonScan <br />
            <img alt="ninth step" src="/mobile-9.jpg" />
          </li>
          <li>
            ท่านได้รับ NFT เรียบร้อยแล้ว กดปุ่ม "ปิด" เพื่อดูรายละเอียด NFT <br />
            <img alt="tenth step" src="/mobile-10.jpg" />
          </li>
          <li>
            ท่านสามารถตรวจสอบการเป็นเจ้าของ NFT จากข้อมูลของ OpenSea ซึ่งเป็นตลาดกลางสำหรับซื้อ-ขาย NFT <br />
            และสามารถตรวจสอบธุรกรรมบนเครือข่ายบล็อกเชนผ่านเว็บไซต์ PolygonScan <br />
            <img alt="eleventh step" src="/mobile-11.jpg" />
          </li>
        </ol>        
        {/* <p>{text}</p> */}
      </Collapse.Panel>
      <Collapse.Panel header="การรับรางวัลโดยใช้คอมพิวเตอร์" key="desktop">
        {/* <p>{text}</p> */}
        <ul>
          <li>เข้าเว็บไซต์การรับรางวัล ผ่านลิงก์ที่ท่านได้รับ (https://nft.thai.run/bs42/************) โดยใช้บราวเซอร์ Chrome / Firefox / Brave / Edge</li>
          <li>การรับรางวัลจะต้องใช้ ดิจิทัลวอลเล็ท MetaMask หากท่านยังไม่ได้ติดตั้งส่วนขยายของบราวเซอร์ ท่านสามารถคลิกที่ปุ่ม "ติดตั้ง MetaMask Extension" เพื่อดำเนินการต่อไป</li>
        </ul>
        <Collapse ghost>
          <Collapse.Panel header="การติดตั้ง MetaMask Extension" key="extension">
            <a href="https://www.youtube.com/watch?v=z5IJUA1wgiE" target="_blank" rel="noreferrer">ดูคลิปอธิบายขั้นตอนการติดตั้ง MetaMask Extension</a>
          </Collapse.Panel>
        </Collapse>
        <ol>
          <li>
            เข้าเว็บไซต์การรับรางวัล โดยการสแกน QR Code หากท่านยังไม่ได้ติดตั้ง MetaMask ให้สแกน QR Code ผ่านแอปพลิเคชันกล้องถ่ายรูป หรือ LINE <br />
            ท่านสามารถคลิกที่ปุ่ม "ติดตั้ง MetaMask Extension" เพื่อดำเนินการต่อไป <br />
            <img alt="initial step" src="/desktop-0.png" /><br />
            {/* เมื่อติดตั้งสำเร็จแล้ว ให้ท่านสแกน QR Code อีกครั้งผ่านแอปพลิเคชัน MetaMask */}
            เมื่อติดตั้ง Extension เรียบร้อยแล้ว ให้ท่านเข้าเว็บไซต์การรับรางวัล (https://nft.thai.run/bs42/************) <br />
            และเชื่อมต่อกับดิจิทัลวอลเล็ท โดยการคลิกปุ่ม "เชื่อมต่อด้วย MetaMask" <br />
            <img alt="first step" src="/desktop-1.png" />
          </li>
          <li>
            ยืนยันการเชื่อมต่อ เลือก Account ที่ต้องการเชื่อมต่อ และคลิก "Next" <br />
            <img alt="third step" src="/desktop-2.png" />
          </li>
          <li>
            ยืนยันการเชื่อมต่อ โดยการคลิกปุ่ม "Connect" <br />
            <img alt="third step" src="/desktop-3.png" />
          </li>
          <li>
            เนื่องจากรางวัล ถูกบันทึกอยู่บนเครือข่าย Polygon Mainnet หากท่านยังไม่เคยเชื่อมต่อกับเครือข่ายนี้ <br />
            จะต้องอนุญาตให้ดิจิทัลวอลเล็ทของท่านทำการเชื่อมต่อ โดยการคลิก "Approve" <br />
            <img alt="fourth step" src="/desktop-4.png" />
          </li>
          <li>
            ทำการเชื่อมต่อกับเครือขาย Polygon Mainnet โดยการคลิก "Switch Network" <br />
            <img alt="fifth step" src="/desktop-5.png" />
          </li>
          <li>
            ระบบจะทำการดาวน์โหลดข้อมูล NFT อาจใช้เวลาสักครู่ <br />
            ท่านสามารถรับ NFT โดยการคลิก "รับรางวัล"<br />
            <img alt="sixth step" src="/desktop-6.png" />
          </li>
          <li>
            คลิก "Sign" เพื่อยืนยันตัวตนเพื่อทำธุรกรรมบนบล็อกเชน <br />
            <img alt="seventh step" src="/desktop-7.png" />
          </li>
          <li>
            รอการตรวจสอบรหัสขั้นตอนนี้อาจใช้เวลาถึง 1 นาที <br />
            <img alt="eighth step" src="/desktop-8.png" />
          </li>
          <li>
            เครือข่ายบล็อกเชนได้รับข้อมูลธุรกรรม การโอน NFT แล้ว และกำลังตรวจสอบความถูกต้อง<br />
            ขั้นตอนนี้อาจใช้เวลาถึง 10 นาที ท่านสามารถดูรายละเอียดธุรกรรมได้ โดยคลิกลิงก์ PolygonScan <br />
            <img alt="ninth step" src="/desktop-9.png" />
          </li>
          <li>
            ท่านได้รับ NFT เรียบร้อยแล้ว คลิกปุ่ม "ปิด" เพื่อดูรายละเอียด NFT <br />
            <img alt="tenth step" src="/desktop-10.png" />
          </li>
          <li>
            ท่านสามารถตรวจสอบการเป็นเจ้าของ NFT จากข้อมูลของ OpenSea ซึ่งเป็นตลาดกลางสำหรับซื้อ-ขาย NFT <br />
            และสามารถตรวจสอบธุรกรรมบนเครือข่ายบล็อกเชนผ่านเว็บไซต์ PolygonScan <br />
            <img alt="eleventh step" src="/desktop-11.png" />
          </li>
        </ol>
      </Collapse.Panel>
    </Collapse>
    <a href="https://m.me/thaidotrun" target="_blank" rel="noreferrer">สอบถามข้อมูลเพิ่มเติมได้ที่ inbox ThaiRun</a>
  </div>
}

export default How