import { Typography } from "antd"
import { ArrowUpOutlined } from '@ant-design/icons'

const Home = () => {
  return <div style={{ margin: '2em', textAlign: 'center' }}>
    <Typography.Title level={2}>ThaiRun NFT ✕ BS42</Typography.Title>
    <p>
    นักวิ่งงาน BANGSAEN42-2021 ที่ได้รับรางวัล Top100 / Top10 Senior ทุกท่าน <br />
    จะได้รับซองสีขาวหรือสีชมพู ภายในมี QR Code เพื่อใช้รับรางวัลในรูปแบบ NFT
    </p>
    <p style={{ margin: '2em' }}>
      <a href="/how" target="_blank" rel="noreferrer">ดูขั้นตอนการรับรางวัล NFT <ArrowUpOutlined style={{ transform: 'rotate(45deg)' }} /></a>
    </p>
  </div>
}

export default Home