import { useEffect, useState } from "react"
// import { constants } from 'ethers'
import { getSignatureMessage } from '@common/utils'
import { getIpfsUrl } from './ipfs'
import { firestore } from './firebase'
import * as api from './api'
import { doc, getDoc } from 'firebase/firestore'
import { useDapp } from "./dapp"
import axios from "axios"

export  const useClaimCode = ({ event, code }) => {
  const { signer, provider } = useDapp()
  const [txStatus, setTxStatus] = useState()
  const [txHash, setTxHash] = useState()

  const claim = async () => {
    // console.log('claim')
    setTxStatus('signing')
    const signature = await signer.signMessage(getSignatureMessage(code))
    // console.log({ signature })
    // TODO: handle rejection
    setTxStatus('claiming')
    // console.log('claiming', { event, code, signature })
    const response = await api.claim({ event, code, signature })
    // console.log({ response })
    // TODO: handle Loading claimed
    // TODO: handle network error
    const { hash } = response.data
    setTxHash(hash)
    setTxStatus('confirming')
    await provider.waitForTransaction(hash)
    setTxStatus('claimed')
  }
  return {
    claim,
    txStatus,
    txHash
  }
}

export const useData = ({ event, code }) => {
  const { contract } = useDapp()
  const [tokenId, setTokenId] = useState()
  const [tokenMetadata, setTokenMetadata] = useState()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const [owner, setOwner] = useState(null)
  const [tx, setTx] = useState()
  useEffect(() => {
    setLoading(true)
    // console.log('useEffect', contract, code)
    if (!contract) {
      return
    }
    getDoc(doc(firestore, `events/${event}/tokens`, code)).then(async snapshot => {
      const tokenData = snapshot?.data()
      // console.log({ tokenData })
      if (!tokenData) {
        setLoading(false)
        // TODO: handle
        setError(new Error('INCORRECT_CODE'))
        return
      }
      const { tokenId, tokenUri, tx } = tokenData
      console.log({ tx })
      // console.log({ tokenId, tokenUri })
      // setLoading(false)
      setTokenId(tokenId)
      setTx(tx)
      const { data } = await axios.get(tokenUri.startsWith('ipfs') ? getIpfsUrl(tokenUri) : tokenUri)
      setTokenMetadata(data)
      console.log({ tokenId }, contract.address)
      try {
        const ownerAddress = await contract.ownerOf(tokenId)
        setOwner(ownerAddress)
      } catch (e) {
        console.error(e)
        if (e?.data?.code === 3) {
          setOwner(null)
        }
      }
      // const r = await contract['ownerOf(uint256)'](tokenId)
      // console.log({ r })
      // console.log({ data })
      // console.log('filtering')
      // const filter = contract?.filters.Transfer(constants.AddressZero, null, tokenId)
      // const events = await contract?.queryFilter(
      //   filter,
      //   parseInt(process.env.REACT_APP_CONTRACT_CREATION_BLOCK),
      //   "latest"
      // )
      // setIsClaimed(events.length > 0)
    }).catch(setError).finally(() => setLoading(false))
  }, [event, code, contract])
  return {
    loading,
    tokenId,
    tokenMetadata,
    tx,
    error,
    owner
  }
}
