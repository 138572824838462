import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import { BrowserRouter, Routes, Route } from 'react-router-dom'
// import App from './App';
import reportWebVitals from './reportWebVitals';
// import { network } from './config';
// const chainId = process.env.REACT_APP_CHAIN_ID
import Root from './components/Root';
import Home from './pages/Home';
import How from './pages/How';
import Claim from './components/_Claim';
import ClaimCode from './components/ClaimCode';
import { DappProvider } from './services/dapp';
import BSNFT from '@contracts/BSNFT.sol/BangsaenSeries.json'
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log({ error, errorInfo })
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return this.props.children
      // You can render any custom fallback UI
      // return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}


ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <DappProvider contractAddress={process.env.REACT_APP_CONTRACT_ADDRESS} contractAbi={BSNFT.abi}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Root /> }>
              <Route index element={<Home />} />{/* Home: explanation + links */}
              <Route path="how" element={<How />} />
              <Route path=":event">
                <Route index element={<Claim />} />{/* Enter code */}
                <Route path=":code" element={<ClaimCode/>} />
              </Route>
              {/* <App /> */}
            </Route>
          </Routes>
        </BrowserRouter>
      </DappProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
