
import { initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics"

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore()
const _analytics = getAnalytics();

if (process.env.NODE_ENV === 'development' && 
  (!process.env.REACT_APP_USE_STAGING_BACKEND || !JSON.parse(process.env.REACT_APP_USE_STAGING_BACKEND))
) {
  connectFirestoreEmulator(db, 'localhost', 8080)
}
export default app
export const firestore = db
export const analytics = _analytics
