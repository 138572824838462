const getSignatureMessage = (code) => `ยืนยันการรับ NFT ด้วยรหัส: ${code}

Confirm receiving NFT with code: ${code}`

// const getIpfsHttpUri = (ipfsUri) => ipfsUri?.replace('ipfs://', process.env.REACT_APP_IPFS_BASE_URI)

module.exports = {
  getSignatureMessage,
  // getIpfsHttpUri
}
// export 